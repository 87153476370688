.image-container {
    border-radius: 25px;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    margin-top: 12px;
    max-width: calc(100% - 2vw);
    margin-left: 3px;

    img {
        border-radius: 25px;
        width: 100%;
    }
}
