@tailwind base;
@tailwind components;
@tailwind utilities;

.alignMiddle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  height: calc(100vh - 100px);
  margin: 10px;
}

.scanTitle label {
  font-size: 3rem !important;
  margin-bottom: 40px;
}

.scanTitle input {
  font-size: 2rem !important;
}

.modal_failure {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 0, 0, 0.15);
}
