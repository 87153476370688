$color--primary: #FE5000;
$color--green: #3E8E41;
$color--orange: #FF9900;
$color--red: #9B2D2F;
$color--blue: #4567B7;
$color--black: #000000;

.trinity-toggle-button {
  background-color: white;
  border-radius: 30px;
  border: 1.5px solid #D5D7DB;
  color: var(--eds-color-text-default);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  padding: 36px 20px;
  text-transform: uppercase;
  transition: background 0.5s, box-shadow 0.3s, transform 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  box-sizing: border-box;
  text-decoration: none;
  vertical-align: baseline;
  min-width: 260px;
  flex: 1 1 auto;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  &:active {
    background-color: darken($color--primary, 20%);
    transform: scale(0.95);
  }

  &.active {
    border-color: black;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  &.disabled {
    background-color: var(--eds-color-grey-3);
    border-color: var(--eds-color-grey-3);
    color: var(--eds-color-grey-1);
    cursor: not-allowed;
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.massive {
    font-size: 3rem;
    line-height: 1.5;
    border-width: 4px;
    box-shadow: 0 6px 8px rgba(0,0,0,0.3);
  }

  &.background-green {
    background-color: $color--green;
    border-color: darken($color--green, 2%);
    color: white;
  }

  &.background-orange {
    background-color: $color--orange;
    border-color: darken($color--orange, 2%);
    color: white;
  }

  &.background-red {
    background-color: $color--red;
    border-color: darken($color--red, 2%);
    color: white;
  }

  &.background-blue {
    background-color: $color--blue;
    border-color: $color--blue;
    color: white;
  }
  &.background-black {
    background-color: $color--black;
    border-color: $color--black;
    color: white;
  }

  &:disabled {
    background-color: var(--eds-color-grey-1);
    border-color: var(--eds-color-grey-1);
    color: white;
    cursor: not-allowed;
  }

  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.7);
    border-top: 8px solid #444;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    opacity: 1;
    animation: spin 1s linear infinite;
    transition: opacity 0.25s ease;
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}
