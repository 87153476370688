$color-white: #FFFFFF;
$color-black: #000000;
$color-gray-500: #6B7280;
$color-gray-400: #9CA3AF;
$color-gray-300: #D1D5DB;
$color-gray-100: #F3F4F6;
$color-gray-50: #F9FAFB;

$border-color: $color-gray-300;
$text-base: 2rem;
$padding-standard: 10px;
$transition-standard: 0.1s ease;

.country-select {
  position: relative;

  &__trigger {
    width: 100%;
    background-color: $color-white;
    border: 1px solid $border-color;
    padding: $padding-standard;
    border-radius: var(--eds-radii-4);
    cursor: pointer;
    font-size: $text-base;
    transition: border-color $transition-standard;

    &:hover {
      border-color: $color-gray-400;
    }

    &--has-value {
      color: $color-black;
    }

    &--placeholder {
      color: $color-gray-500;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    margin-top: 2px;
    background-color: $color-white;
    border: 1px solid $border-color;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 50;
    opacity: 0;
    transform: translateY(-10px);
    animation: dropdownEnter 0.1s ease forwards;
    @keyframes dropdownEnter {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }


  &__search-input {
    width: 100%;
    padding: $padding-standard;
    border: none;
    border-bottom: 1px solid $border-color;
    font-size: $text-base;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-gray-500;
    }
  }

  &__options-container {
    max-height: 380px;
    overflow-y: auto;
  }

  &__option {
    padding: $padding-standard;
    cursor: pointer;
    font-size: $text-base;

    &:hover {
      background-color: $color-gray-100;
    }

    &--focused {
      background-color: $color-gray-100;
      border-left: 2px solid $color-gray-400;
    }
  }

  &__no-results {
    padding: $padding-standard;
    color: $color-gray-500;
    font-size: $text-base;
  }
}