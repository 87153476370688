.keypad-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.keypad-icon {
  opacity: 0.3;
}

.numpad-buttons button {
  font-size: 4rem;
  line-height: 4rem;
  background-color: #fff;
}
