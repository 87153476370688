.grade-button-group-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 24px;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 12px;
  width: 100%;
  height: 100%;
}
.grid-button {
  padding: 20px;

  &.full-left {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
  &.full-top {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
}
